import React, { useEffect, useRef, useState } from 'react';
import { LoanTenorField, LoanProductField, LoanAmountField } from './Fields';
import * as S from './styled';
import { Alert, DataCard } from '../../../components';
import {  useMutation } from '@apollo/client';
import { INITIATE_APPLICATIONS } from '../mutations';
import { SubmitButton } from '../../../components/FormikElements';
import { handleGraphQLErrors } from '../../../lib/utils';
import { Redirect, useHistory } from 'react-router-dom';
import moment from 'moment';
import { useLoanApplicationContext } from '../../../hooks';


const LoanInitiationScreen = () => {
  const [initiateError, setInitiateError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [activeScreen, setActiveScreen] = useState('product-category');
  const [showAmountField, setShowAmountField] = useState(false);
  const [showTenorField, setShowTenorField] = useState(false);
  const [pendingApplication, setPendingApplication] = useState(null);
  const [clientInfo, setClientInfo] = useState({});

  const [applicationState, setApplicationState] = useState({
    loanCategoryId: null,
    loanAmount: null,
    loanDuration: null,
    cards: null,
  });

  const {
    user,
    account,
    userLoading: viewerLoading,
  } = useLoanApplicationContext();

  const errorRef = useRef(null);
  const history = useHistory();


  useEffect(() => {
    if (user?.bvnStatus?.bvn && account && user?.kycInformation) {
      const applications = account?.applications?.nodes;

      const applicationCount = applications?.length || 0;

      if (applicationCount === 0 && !user?.bvnStatus?.bvn) {
        history.push('/v1/sign-up/bvn-verification', { login: true });
        return;
      }

      if (applicationCount === 0 && !user?.kycInformation) {
        history.push('/v1/sign-up/customer-info', { login: true });
        return;
      }

      const pending = applications?.find(
        application => application?.status?.name === 'PENDING',
      );
      const cards = account?.cards;
      setApplicationState(state => ({
        ...state,
        cards,
      }));

      setPendingApplication(pending);
    }
  }, [user?.kycInformation, account, user?.bvnStatus?.bvn, history]);

  const goToApplicationPage = applicationNumber => {
    return `/application/${applicationNumber}`;
  };

  const [initiateApplication, { loading }] = useMutation(
    INITIATE_APPLICATIONS,
    {
      onCompleted({ initiateApplication }) {
        const { application } = initiateApplication;
        history.push(goToApplicationPage(application?.applicationNumber));
      },
      onError(error) {
  
        setInitiateError(
          handleGraphQLErrors(error) ||
            'There was an error starting your loan application',
        );
      },
    },
  );

  const handleInitiate = () => {
    setInitiateError('');
    const loanDuration = applicationState?.loanDuration.split(' ');
    const repaymentDate = moment().add(loanDuration[0], loanDuration[1]);
    const dayOfRepayment = repaymentDate.format('D');

    const durationType = () => {
      switch (loanDuration[1]) {
        case 'days':
          return 'DAILY';
        case 'months':
          return 'MONTHLY';
        case 'weeks':
          return 'WEEKLY';
        case 'years':
          return 'ANNUALLY';
        default:
          return 'DAILY';
      }
    };

    const variables = {
      clientId: clientInfo?.clientId,
      amount: applicationState?.loanAmount,
      loanCategoryId: applicationState?.loanCategoryId,
      loanDuration: parseInt(loanDuration[0]),
      durationType: durationType(),
      dateOfRepayment: repaymentDate,
      dayOfRepayment: parseInt(dayOfRepayment),
      source: 'WEB', //added source
    };

    initiateApplication({
      variables,
    });
  };

  useEffect(() => {
    initiateError &&
      errorRef.current &&
      errorRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [initiateError]);

  if (viewerLoading) return <></>;

  if (!viewerLoading && pendingApplication) {
    return (
      <Redirect
        push
        to={goToApplicationPage(pendingApplication?.applicationNumber)}
      />
    );
  }

  return (
    <>
      <S.Wrapper>
        <DataCard loading={isLoading || loading || viewerLoading}>
          {(initiateError) && (
            <div ref={errorRef}>
              <Alert classes="error">{initiateError}</Alert>
            </div>
          )}

          <S.InitiateWrapper>
            <LoanProductField
              setIsLoading={setIsLoading}
              setShowAmountField={setShowAmountField}
              applicationState={applicationState}
              showAmountField={showAmountField}
              setApplicationState={setApplicationState}
              isActive={activeScreen === 'product-category'}
              setActiveScreen={setActiveScreen}
            />

            {showAmountField && (
              <LoanAmountField
                key={applicationState?.loanCategoryId}
                setIsLoading={setIsLoading}
                setShowTenorField={setShowTenorField}
                showTenorField={showTenorField}
                applicationState={applicationState}
                setApplicationState={setApplicationState}
                setClientInfo={setClientInfo}
                isActive={activeScreen === 'loan-amount'}
                setActiveScreen={setActiveScreen}
              />
            )}

            {showTenorField && !!applicationState?.loanAmount && (
              <LoanTenorField
                setIsLoading={setIsLoading}
                key={applicationState?.loanAmount}
                applicationState={applicationState}
                setApplicationState={setApplicationState}
                clientInfo={clientInfo}
                isActive={activeScreen === 'loan-tenor'}
                setActiveScreen={setActiveScreen}
              />
            )}

            <section style={{ marginTop: '-3rem' }}>
              {applicationState?.loanDuration && (
                <SubmitButton
                  value="Start Application"
                  onClick={handleInitiate}
                  disabled={!applicationState?.loanDuration}
                />
              )}
            </section>
          </S.InitiateWrapper>
        </DataCard>
      </S.Wrapper>
    </>
  );
};

export default LoanInitiationScreen;
