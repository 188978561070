import React from 'react';
import { Switch, Redirect, useRouteMatch } from 'react-router-dom';
import Notifications from 'react-notify-toast';
import SignupRoute from '../SignupRoute';
import { SignupProvider } from '../SignupContext';
import * as S from './styled';
import CreateNewAccount from '../CreateNewAccount';
import MinimumRequirements from '../MinimumRequirements';
import BvnVerification from '../BvnVerification';
import CustomerInfo from '../CustomerInfo';

const Signup = ({ locationState, location }) => {
  const { path } = useRouteMatch();
  return (
    <SignupProvider>
      <Notifications />
      <S.Progressbar pathname={location.pathname}>
        <div></div>
      </S.Progressbar>

      <Switch>
        <SignupRoute
          path={`${path}/create-account`}
          component={CreateNewAccount}
        />

        <SignupRoute
          path={`${path}/minimum-requirements`}
          component={MinimumRequirements}
        />
        <SignupRoute
          path={`${path}/bvn-verification`}
          component={BvnVerification}
        />

        <SignupRoute path={`${path}/customer-info`} component={CustomerInfo} />

        <Redirect
          to={{
            pathname: `${path}/create-account`,
            state: locationState,
          }}
        />
      </Switch>
    </SignupProvider>
  );
};

export default Signup;
