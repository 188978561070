/* eslint-disable prettier/prettier */
import { useCallback, useContext, useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useHistory } from 'react-router';
import { notify } from 'react-notify-toast';
import { useGetCategories, useUserViewer } from '../../../hooks';
import { handleGraphQLErrors } from '../../../lib/utils';
import {
  LATEST_APPLICATION_FORMDATA,
  SAVE_CUSTOM_FORM,
} from '../../LoanApplication/queries';
import { ApplicationContext } from '../ApplicationContext';
import constants from '../../../lib/constants';
import { UPLOAD_IMAGE } from '../../../hooks/useImageUploader';
import { UPLOAD_SUPPORTING_DOCUMENT } from '../../../hooks/useDocumentUploader';

export const useCustomForm = () => {
  const [, , applicationState, , categoryId] = useContext(ApplicationContext);
  const { viewerData, loading: userLoading } = useUserViewer();
  const user = viewerData?.loaded?.viewer?.me;
  const account = applicationState?.data?.application.account;
  const applicationId = applicationState?.data?.application.id;
  const applicationNumber =
    applicationState?.data?.application.applicationNumber;
  const customApplicationForm =
    applicationState?.data?.application.customApplicationForm;
  const { status, duration } = constants;
  const { loanCategories, loading } = useGetCategories();
  const history = useHistory();

  const showErrorAlert = (text, d = duration.SHORT) => {
    notify.show(text, status.ERROR, d);
  };
  const [formIndex, setFormIndex] = useState(0);
  const [customPath, setCustomPath] = useState(0);
  const { products } =
    loanCategories.find(category => category.id === categoryId) || {};

  const [defaultFormValues, setDefaultFormValues] = useState(
    customApplicationForm
      ? JSON.parse(JSON.stringify(customApplicationForm))
      : null,
  );

  useEffect(() => {
    if (customApplicationForm) {
      const { step, path } = customApplicationForm;
      setCustomPath(path);
      if (step) {
        setFormIndex(step);
      }
    }
  }, [customApplicationForm]);

  const [applicationForm, setApplicationForm] = useState([]);

  const handleNext = () => {
    setFormIndex(formIndex + 1);
  };

  const [mutate, { loading: saveFormLoading }] = useMutation(SAVE_CUSTOM_FORM, {
    onCompleted({ saveCustomApplicationForm }) {
      if (saveCustomApplicationForm && saveCustomApplicationForm.success) {
        const { path, step } = saveCustomApplicationForm.data || {};
        const activeFormTabs = applicationForm?.filter((tab) => !tab.linkedToOption)
        if (path) {
          setCustomPath(saveCustomApplicationForm.data?.path);
          return;
        }
        if (step < activeFormTabs?.length && !path) {
          setCustomPath('');
          handleNext();
        }  else {
          history.push(`/application-custom/${applicationNumber}/breakdown`);
        }  
      }
    },
    onError(error) {
      const errorMessage = handleGraphQLErrors(error);
      showErrorAlert(
        errorMessage || 'There was an error saving data please try again later',
      );
    },
  });

  const [getLatestForm, { loading: getLatestLoading }] = useLazyQuery(
    LATEST_APPLICATION_FORMDATA,
    {
      onError(error) {
        showErrorAlert(handleGraphQLErrors(error));
      },
      onCompleted(data) {
        if (data?.latestApplicationFormData?.data && !defaultFormValues) {
          setDefaultFormValues(
            JSON.parse(JSON.stringify(data?.latestApplicationFormData?.data)),
          );
          setFormIndex(formIndex);
        }
      },
    },
  );

  useEffect(() => {
    if (!loading) {
      setApplicationForm(products && products[0]?.applicationForm);
    }
  }, [loading, products]);

  useEffect(() => {
    if (!userLoading && account?.name) {
      getLatestForm({ variables: { customerId: account.name } });
    }
  }, [userLoading, account?.name]);

  const reFormatFormData = formData => {
    const { name } = applicationForm[formIndex] || {};

    const keySet = new Set(formData[name]?.files?.map(item => item.name));

    const filteredFormData = Object.keys(formData[name])
      .filter(key => !keySet.has(key))
      .reduce((result, key) => {
        result[key] = formData[name][key];
        return result;
      }, {});

    return filteredFormData;
  };

  const [mutateImage, { loading: uploadImageLoading }] = useMutation(
    UPLOAD_IMAGE,
    {
      onError(error) {
        const errorMessage = handleGraphQLErrors(error);
        showErrorAlert(
          errorMessage ||
            'There was an error saving data please try again later',
        );
      },
    },
  );

  const [mutateFile, { loading: uploadDocumentLoading }] = useMutation(
    UPLOAD_SUPPORTING_DOCUMENT,
    {
      onError(error) {
        const errorMessage = handleGraphQLErrors(error);
        showErrorAlert(
          errorMessage ||
            'There was an error saving data please try again later',
        );
      },
    },
  );

  const uploadSelectedImage = async (selectedFile, fileName, type) => {
    const { validity, file } = selectedFile;
    if (validity) {
      const { data } = await mutateImage({
        variables: { image: file, fileName, type },
      });
      return data;
    }
  };

  const uploadSelectedFile = async (selectedFile, documentName, userId) => {
    const { validity, file } = selectedFile;
    if (validity) {
      const { data } = await mutateFile({
        variables: { file, documentName, userId },
      });
      return data;
    }
  };

  const handleSubmit = async formData => {
    const { name } = applicationForm[formIndex] || {};
    if (formData[name]?.files.length > 0) {
      const newFiles = formData[name]?.files?.map(async file => {
        const { url, key, bucket } = await handleUploads(file);
        file =
          file.name === 'governmentId'
            ? {
                name: file?.name,
                fileDetails: {
                  url,
                  key,
                  bucket,
                  documentName: file?.documentName,
                  documentNumber: file?.documentNumber,
                },
              }
            : {
                name: file?.name,
                fileDetails: {
                  url,
                  key,
                  bucket,
                },
              };
        return file;
      });
      formData[name].files = await Promise.all(newFiles);
      const filteredFormData = reFormatFormData(formData);
      formData[name] = filteredFormData;
    }
    mutate({
      variables: {
        applicationId,
        data: { ...formData, step: formIndex + 1, path: '' },
      },
    });
  };

  const handleUploads = async ({
    name,
    fileDetails,
    documentName,
    documentNumber,
  }) => {
    if (name === 'workId' || name === 'selfie') {
      return await handleImageUpload(name, fileDetails, user);
    }
    if (name === 'governmentId') {
      return await handleGovernmentIdUpload(
        documentName,
        documentNumber,
        fileDetails,
        user,
      );
    }
    return await handleDocumentUpload(name, fileDetails, user);
  };

  const handleImageUpload = useCallback(
    async (name, fileDetails, currentUser) => {
      const type = name === 'workId' ? 'WORKID' : 'PASSPORT';
      const namePrefix = name === 'workId' ? 'work_id_' : 'selfie_';
      const { firstName, lastName, id } = currentUser || {};
      const fileName = `${namePrefix}${firstName}_${lastName}_${id}`;
      const { uploadImageAndSaveToUserMetaData } = await uploadSelectedImage(
        fileDetails,
        fileName,
        type,
      );
      const { fileUrl, key, bucket } = uploadImageAndSaveToUserMetaData || {};
      return { key, bucket, url: fileUrl };
    },
    [],
  );

  const handleGovernmentIdUpload = useCallback(
    async (documentName, documentNumber, fileDetails, currentUser) => {
      const type = 'GOVERNMENTID';
      const { firstName, lastName, id } = currentUser;
      const fileName = `${documentName}_${documentNumber}_${firstName}_${lastName}_${id}`;
      const { uploadImageAndSaveToUserMetaData } = await uploadSelectedImage(
        fileDetails,
        fileName,
        type,
      );
      const { fileUrl, key, bucket } = uploadImageAndSaveToUserMetaData || {};
      return { key, bucket, url: fileUrl };
    },
    [],
  );

  const handleDocumentUpload = useCallback(
    async (name, fileDetails, currentUser) => {
      const { firstName, lastName, id } = currentUser || {};
      const fileName = `${name}_${firstName}_${lastName}_${id}`;
      const { uploadSupportingDocument } = await uploadSelectedFile(
        fileDetails,
        fileName,
        id,
      );
      const { file } = uploadSupportingDocument || {};
      return { url: file?.url, bucket: file?.bucket, key: file?.key };
    },
    [],
  );

  return {
    applicationForm,
    handleNext,
    formIndex,
    applicationId,
    applicationNumber,
    customApplicationForm,
    account,
    handleSubmit,
    saveFormLoading,
    loading,
    defaultFormValues,
    getLatestLoading,
    mutate,
    customPath,
    uploadDocumentLoading,
    uploadImageLoading,
  };
};
