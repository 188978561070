/* eslint-disable prettier/prettier */
import React, { useEffect } from 'react';
import {
  BankStatementUpload,
  ConfirmRequest,
  EmailVerification,
  Mbs,
  PhoneVerification,
  RefereeDetails,
  UploadSelfie,
  WorkEmailVerification,
  GovernmentIDCard,
  WorkIdUploadScreen,
} from '../../LoanApplication';
import { DataCard } from '../../../components';
import { Switch, useRouteMatch, useHistory } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import { useCustomForm } from './useCustomForm';
import { ApplicationProvider } from '../ApplicationContext';
import './_CustomApplication.scss';
import MbsOtp from '../../LoanApplication/Mbs/components/MbsOtp';
import MbsInstruction from '../../LoanApplication/Mbs/components/MbsInstruction';
import { NEW_LOAN_ROUTES } from '../../LoanApplication/routes';
import CustomFormApplicationRoute from './CustomFormApplicationRoute';
import FormBuilder from './FormBuilder';

const CustomApplicationRouter = () => {
  const {
    formIndex,
    applicationForm,
    handleSubmit,
    defaultFormValues,
    saveFormLoading,
    getLatestLoading,
    loading,
    mutate,
    uploadDocumentLoading,
    uploadImageLoading,
  } = useCustomForm();

  const { path, params: { applicationNumber } } = useRouteMatch();
  const getPath = route => `${path}/${route}`;
  const activeFormTabs = applicationForm?.filter((tab) => !tab.linkedToOption);
  const history = useHistory();

  useEffect(() => {
    if (activeFormTabs?.length && formIndex >= activeFormTabs?.length) {
      history.push(`/application-custom/${applicationNumber}/${NEW_LOAN_ROUTES.confirmLoan}`);
    }
  }, [applicationForm])

  return (
    <DataCard
      loading={
        getLatestLoading ||
        saveFormLoading ||
        loading ||
        uploadDocumentLoading ||
        uploadImageLoading
      }
    >
      {Array.isArray(activeFormTabs) && activeFormTabs?.length > 0 && (
        <Switch>
          <CustomFormApplicationRoute path={getPath(NEW_LOAN_ROUTES.mbs)} exact>
            <Mbs customForm={true} handleNext={mutate} formIndex={formIndex} />
          </CustomFormApplicationRoute>
          <CustomFormApplicationRoute
            path={getPath(NEW_LOAN_ROUTES.mbsOtp)}
            exact
          >
            <MbsOtp
              customForm={true}
              handleNext={mutate}
              formIndex={formIndex}
            />
          </CustomFormApplicationRoute>
          <CustomFormApplicationRoute
            path={getPath(NEW_LOAN_ROUTES.mbsInstruction)}
            exact
          >
            <MbsInstruction
              customForm={true}
              handleNext={mutate}
              formIndex={formIndex}
            />
          </CustomFormApplicationRoute>

          <CustomFormApplicationRoute
            path={getPath(NEW_LOAN_ROUTES.verifyEmail)}
            exact
          >
            <EmailVerification 
              customForm={true} handleNext={mutate} formIndex={formIndex}
            />
          </CustomFormApplicationRoute>

          <CustomFormApplicationRoute
            path={getPath(NEW_LOAN_ROUTES.verifyPhone)}
            exact
          >
            <PhoneVerification 
             customForm={true} handleNext={mutate} formIndex={formIndex}
            />
          </CustomFormApplicationRoute>

          <CustomFormApplicationRoute
            path={getPath(NEW_LOAN_ROUTES.verifyWorkEmail)}
            exact
          >
            <WorkEmailVerification 
             customForm={true} handleNext={mutate} formIndex={formIndex}
            />
          </CustomFormApplicationRoute>

          <CustomFormApplicationRoute
            path={getPath(NEW_LOAN_ROUTES.passportUpload)}
            exact
          >
            <UploadSelfie 
             customForm={true} handleNext={mutate} formIndex={formIndex}
            />
          </CustomFormApplicationRoute>

          <CustomFormApplicationRoute
            path={getPath(NEW_LOAN_ROUTES.referee)}
            exact
          >
            <RefereeDetails 
            customForm={true} handleNext={mutate} formIndex={formIndex}
            />
          </CustomFormApplicationRoute>

          <CustomFormApplicationRoute
            path={getPath(NEW_LOAN_ROUTES.idUpload)}
            exact
          >
            <GovernmentIDCard 
             customForm={true} handleNext={mutate} formIndex={formIndex}
            />
          </CustomFormApplicationRoute>

          <CustomFormApplicationRoute
            path={getPath(NEW_LOAN_ROUTES.workIdUpload)}
            exact
          >
            <WorkIdUploadScreen 
             customForm={true} handleNext={mutate} formIndex={formIndex}
            />
          </CustomFormApplicationRoute>
          
          <CustomFormApplicationRoute
            path={getPath(NEW_LOAN_ROUTES.confirmLoan)}
            exact
          >
            <ConfirmRequest />
          </CustomFormApplicationRoute>
          <CustomFormApplicationRoute
            path={getPath(NEW_LOAN_ROUTES.uploadBankStatement)}
            exact
          >
            <BankStatementUpload
              customForm={true}
              handleNext={mutate}
              formIndex={formIndex}
            />
          </CustomFormApplicationRoute>
          {formIndex < activeFormTabs?.length && (
            <CustomFormApplicationRoute path={`${path}/`}>
              <FormBuilder
                applicationForm={applicationForm}
                handleSubmit={handleSubmit}
                formIndex={formIndex}
                handleNext={mutate}
                defaultFormValues={defaultFormValues}
              />
            </CustomFormApplicationRoute>
          )}
        </Switch>
      )}
    </DataCard>
  );
};

const CustomApplication = () => {
  return (
    <ApplicationProvider>
      <section className="container application form-builder">
        <CustomApplicationRouter />
      </section>
    </ApplicationProvider>
  );
};

export default CustomApplication;
