import gql from 'graphql-tag';

export const CUSTOMER_BVN_STATUS = gql`
  query (
    $bvn: String!
    $clientId: ID!
    $bvnDOB: DateTime
    $bvnPhoneDigits: String
  ) {
    customerBvnStatus(
      input: {
        bvn: $bvn
        clientId: $clientId
        bvnDOB: $bvnDOB
        bvnPhoneDigits: $bvnPhoneDigits
      }
    ) {
      hasCustomerAccount
      firstName
      lastName
    }
  }
`;

export const RESOLVE_ACCOUNT_NUMBER = gql`
  query ($bankId: ID!, $accountNumber: String!) {
    resolveAccountNumber(bankId: $bankId, accountNumber: $accountNumber) {
      accountName
      accountNumber
    }
  }
`;

export const GET_BANKS = gql`
  query ($loanCategoryId: String) {
    getBanks(loanCategoryId: $loanCategoryId) {
      id
      name
      code
      okraSlug
    }
  }
`;

export const GET_ENUM = gql`
  query getEnum($type: Enum!) {
    enumValues(type: $type) {
      name
    }
  }
`;

export const CUSTOMER_ELIGIBILITY_AMOUNTS = gql`
  query ($categoryId: ID) {
    customerAmountEligibility(input: { categoryId: $categoryId }) {
      amount
      paid
    }
  }
`;

export const APPLICABLE_TENORS = gql`
  query ($clientId: ID!, $loanAmount: Float!, $categoryId: ID) {
    applicableTenors(
      input: {
        clientId: $clientId
        loanAmount: $loanAmount
        categoryId: $categoryId
      }
    ) {
      duration
      durationType
    }
  }
`;

export const GET_REPAYMENTBREAKDOWN_QUERY = gql`
  query GetRepaymentBreakdown(
    $policyId: ID!
    $principalAmount: Float!
    $duration: Int!
  ) {
    getRepaymentBreakdown(
      input: {
        policyId: $policyId
        principalAmount: $principalAmount
        duration: $duration
      }
    ) {
      principalAmount
      duration
      totalInterest
      rateInDecimal
      repaymentFrequency
      repaymentBreakdown {
        principalBalance
        expectedPayment
        interestPortion
        principalPortion
        endingBalance
        dueDate
      }
    }
  }
`;

export const RESOLVE_OKRA_BANK = gql`
  query ($okraBankId: String!) {
    resolveOkraBank(okraBankId: $okraBankId) {
      id
      code
      okraSlug
      name
    }
  }
`;

export const CUSTOMER_REMITA_STATUS = gql`
  query customerRemitaStatus($clientId: ID, $customerPhoneNumber: String) {
    customerRemitaStatus(
      input: { clientId: $clientId, customerPhoneNumber: $customerPhoneNumber }
    ) {
      bankId
      customerId
      companyName
      hasRemitaData
      accountNumber
      countSalaryCredits
      salaryPaymentDetails {
        amount
        bankCode
        paymentDate
        accountNumber
      }
      loanHistoryDetails {
        loanAmount
        loanProvider
        repaymentFreq
        outstandingAmount
        loanDisbursementDate
      }
    }
  }
`;

export const VIEWER_QUERY = gql`
  query ViewerQuery {
    viewer {
      me {
        id
        firstName
        lastName
        email
        phone
        isBvnVerified
        isPhoneConfirmed
        isEmailConfirmed
        kycInformation
        bvnStatus {
          isVerified
          isMatchingPhone
          dateOfBirth
          phone
          bvn
        }
        userMetadata {
          id
          name
          value
        }
      }
      account {
        id
        applications {
          nodes {
            id
            status {
              name
            }
          }
        }
        bankAccounts {
          id
          bank {
            id
            name
          }
        }
        cards {
          id
        }
        contextStates {
          id
          state
          page
          context
        }
      }
    }
  }
`;

export const GET_CUSTOMER_ONEPIPE_ACCOUNT = gql`
  query {
    getCustomerOnePipeAccount {
      id
      accountBanks {
        id
        accountBankId
        bankCode
        bankName
        maskedAccountNumber
      }
      accountCards {
        id
        identifier
        maskedPan
        requireCvv
        requireExpiry
        requirePin
        bankName
        brand
      }
      createdAt
    }
  }
`;

export const GET_LOAN_CATEGORIES = gql`
  query {
    getLoanCategories {
      categories {
        id
        name
        description
        minAmount
        maxAmount
        status
        products {
          id
          name
          description
          applicationForm
        }
      }
    }
  }
`;

export const GET_PAYSTACK_CARD_REFERENCE = gql`
  query getCardReferenceStatus($reference: String!, $loanDuration: String) {
    getCardReferenceStatus(
      input: { reference: $reference, loanDuration: $loanDuration }
    ) {
      reason
      status
      bank
      card {
        maskedPan
      }
    }
  }
`;

export const GENERATE_PAYSTACK_CARD_REFERENCE = gql`
  query ($clientId: ID!) {
    getAddCardReference(input: { clientId: $clientId }) {
      id
      status
      reference
    }
  }
`;

export const SAVE_CUSTOM_FORM = gql`
  mutation ($applicationId: ID!, $data: JSON!) {
    saveCustomApplicationForm(
      input: { applicationId: $applicationId, data: $data }
    ) {
      data
      success
    }
  }
`;

export const LATEST_APPLICATION_FORMDATA = gql`
  query ($customerId: String!) {
    latestApplicationFormData(input: { customerId: $customerId }) {
      data
      success
    }
  }
`;
